/* eslint-disable @next/next/no-img-element */
import { GetStaticPaths, GetStaticPropsResult, NextPage } from 'next/types'
import { useEffect, useState } from 'react'
import { PostProps, PropsI, wpPostType } from '../../../libs/model/post-wp'
import { Body } from '../../../libs/components/header/Header.styled'
import HeaderComponent from '../../../libs/components/header'
import { CardDS, FooterComponent, TagDS } from '../../../libs/components'
import {
    AuthorBioContainer,
    AuthorContainer,
    AuthorsContainer,
    BodyCenter,
    CardContainer,
    DateContainer,
    DiplayAuthorsContainer,
    HTMLContent,
    ImageNews,
    LegendImage,
    PostContainer,
    SponsorBy,
    SponsorImage,
    SponsorImageContainer,
    SponsorParagraph,
    TagContainer,
    TimeStampColumn,
    TimestampContainer,
    UpdateContainer
} from '../../../libs/style/post-page'
import { ProfileCard } from '@JOTAJornalismo/jota-design-system'
import Script from 'next/script'
import { LAYOUT_CONSTANT } from '../../../libs/utils/constants'
import { companyRelationQuoteEnum, generateUUID } from '@App/libs/utils/geral'
import { LoadingPost } from '@App/libs/components/loading/Loading.component'
import BreadCrumComponent from '@App/libs/components/breadcrumb'
import router from 'next/router'
import { URLS } from '@App/libs/utils/constants/route'
import { CONTEUDO_LIBERADO } from '@App/libs/components/home/first-scroll/MiddleColumn.component'
import { JotaInfoEmbeddings } from '@App/libs/utils/info.embedding'
import Metadata from '@App/libs/components/Metadata.component'

export const { SM } = LAYOUT_CONSTANT.BREAKPOINTS
const POST_API_COUNTER = process.env.NEXT_PUBLIC_POST_COUNTER

const PostPage: NextPage<PostProps> = ({ post, header, footer }) => {
    const [show, setShow] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    const [date, setDate] = useState<Date>()
    const [dateRecent, setDateRecent] = useState<Date>()
    const [windowWidth, setWindowWidth] = useState<number>(0)
    const [showIcon, setShowIcon] = useState<boolean>(false)

    useEffect(() => {
        if (typeof window !== 'undefined' && (window as any)) {
            setTimeout(() => {
                setShow(true)
            }, 500)
            setTimeout(() => {
                setLoading(false)
            }, 1000)
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', () =>
            setWindowWidth(window.innerWidth)
        )

        if (post?.date && typeof post.date === 'string') {
            setDate(new Date(post.date))
            const postDate = new Date(post?.date_modified_gmt)
            postDate.setHours(postDate.getHours() - 3)
            setDateRecent(postDate)
        }

        // increment post counter
        if (POST_API_COUNTER) {
            fetch(`${POST_API_COUNTER}/${post.id}/`, {
                method: 'GET'
            })
        }
        return () => {
            setShow(false)
            setLoading(true)
        }
    }, [post, footer, header])

    const getBodyLastParagraph = (node: any) => {
        // EX-4105: tratando imagens e tags no post
        node.querySelectorAll('img').forEach((img: HTMLImageElement) => {
            img.src = img.src.replace(
                'https://www.jota.info/wp-content/uploads/',
                'https://images.jota.info/wp-content/uploads/'
            )

            img.src = img.src.replace(
                'https://portal.jota.info/wp-content/uploads/',
                'https://images.jota.info/wp-content/uploads/'
            )

            img.srcset = img.srcset.replace(
                'https://www.jota.info/wp-content/uploads/',
                'https://images.jota.info/wp-content/uploads/'
            )

            img.srcset = img.srcset.replace(
                'https://portal.jota.info/wp-content/uploads/',
                'https://images.jota.info/wp-content/uploads/'
            )
        })
        node.innerHTML = node.innerHTML.replace(/\[caption[^\]]*\]/g, '')
        node.innerHTML = node.innerHTML.replace(/\[\/caption\]/g, '')

        const nodeChildrenList = [...node.children]
        const nodeChildrenListWithoutNotes = nodeChildrenList.filter(
            (children) => {
                return children.className !== 'notas-rodape'
            }
        ) as HTMLElement[]
        const lastParagraph = nodeChildrenListWithoutNotes?.slice(-1)[0]

        if (!showIcon) {
            appendIconToLastParagraph(node, lastParagraph)
            setShowIcon(true)
        }
    }

    const formatNumbers = (number: any) => {
        return number < 10 ? `0${number}` : number
    }

    const appendIconToLastParagraph = (node: any, lastParagraph: any) => {
        const icon = node.getRootNode() as Document
        const JotinhaIcon = icon.createElement('img')
        JotinhaIcon.src = '/images/favicon.svg'
        JotinhaIcon.id = 'jotinha'
        JotinhaIcon.alt = 'logo-jota'
        lastParagraph.appendChild(JotinhaIcon)
    }

    const updateContainer = (date: any, dateRecent: any) => {
        if (
            date.getDate() / date.getMonth() !==
                dateRecent.getDate() / dateRecent.getMonth() ||
            date.getMinutes() != dateRecent.getMinutes() ||
            date.getHours() != dateRecent.getHours() ||
            dateRecent.getSeconds() - date.getSeconds() > 10
        ) {
            return true
        }
    }

    /**
     * Show Sponsored Logos
     * Description: Priority
     * 1 - post is sponsored
     * 2 - post is from a subcategory of 'Coberturas Especiais'
     * 3 - post category is sponsored
     * @description Show the sponsored logos
     * @param {wpPostType} post
     * @return {JSX.Element}
     */
    const showSponsoredLogos = (post: wpPostType) => {
        const sponsorData: {
            relation: string
            logo: string | string[]
            disclaimer: string
        } = {
            relation: '',
            logo: '',
            disclaimer: ''
        }

        // 1 - post is sponsored
        if (post.sponsor.by_post?.name) {
            sponsorData.relation = post.sponsor.by_post.name
            sponsorData.logo = post.sponsor.by_post.logo
            sponsorData.disclaimer = post.sponsor.by_post.description
        }

        // 2 - post is from a subcategory of 'Coberturas Especiais'
        else if (post.coberturasEspeciais?.disclaimer) {
            sponsorData.relation =
                companyRelationQuoteEnum[
                    post?.coberturasEspeciais
                        ?.relation_type as keyof typeof companyRelationQuoteEnum
                ]
            sponsorData.logo = post.coberturasEspeciais?.company_logo ?? ''
            sponsorData.disclaimer = post.coberturasEspeciais?.disclaimer ?? ''
        }

        // 3 - post category is sponsored
        else if (post.sponsor.by_category?.name) {
            sponsorData.relation = post.sponsor.by_category.name
            sponsorData.logo = post.sponsor.by_category.logos
            sponsorData.disclaimer = post.sponsor.by_category.description
        } else if (
            post.sponsor.by_category == null &&
            post.sponsor.by_post == null &&
            post.coberturasEspeciais == null
        ) {
            return null
        } else {
            return null
        }

        return (
            <>
                <SponsorBy>{sponsorData.relation}</SponsorBy>
                <SponsorImageContainer>
                    {typeof sponsorData.logo === 'string' ? (
                        <SponsorImage
                            src={sponsorData.logo}
                            alt={'Logo do patrocinador'}
                            width={'100%'}
                        />
                    ) : (
                        sponsorData.logo?.map((logo: string) => {
                            return (
                                <SponsorImage
                                    key={generateUUID()}
                                    src={logo}
                                    alt={'Logo do patrocinador'}
                                    width={'100%'}
                                />
                            )
                        })
                    )}
                </SponsorImageContainer>
                <SponsorParagraph>{sponsorData.disclaimer}</SponsorParagraph>
            </>
        )
    }

    return (
        <div className='page' data-page-type='noticia'>
            <Metadata
                title={post.title}
                description={post.excerpt}
                image={post.image.src}
                url={post.slug}
                author={
                    post.authors && post.authors.length > 0
                        ? post.authors.map((author) => author.name).join(', ')
                        : null
                }
                tag={
                    post.tags && post.tags.length > 0
                        ? post.tags
                              .map((tag) => tag.name?.toLowerCase())
                              .join(', ')
                        : null
                }
                yoast={post.yoast}
                dates={post.dates}
            />
            {show && (
                <Body>
                    {header && (
                        <HeaderComponent
                            wpMenu={header.menu}
                            ctaPRO={header.pro}
                            showAcessibility={header.enableAccessibility}
                            signupLink={header.assinePage}
                            bannerTop={header.top_banner}
                        />
                    )}

                    <BodyCenter>
                        {!loading ? (
                            <>
                                <Script
                                    id='TwitterAPI'
                                    src='https://platform.twitter.com/widgets.js'
                                />
                                <Script
                                    id='InstagramAPI'
                                    async
                                    src='//www.instagram.com/embed.js'
                                ></Script>

                                <Script
                                    id='TikTokAPI'
                                    async
                                    src='https://www.tiktok.com/embed.js'
                                />
                                {post.content && show && (
                                    <PostContainer>
                                        <BreadCrumComponent post={post} />
                                        <CardContainer>
                                            <CardDS
                                                RelatedTagLeftIcon='jotaOutrosArticle'
                                                authorTagOnClick={() => {
                                                    return null
                                                }}
                                                authorTagType='colored'
                                                newsLink='https://www.jota.info/'
                                                relatedTagOnClick={function noRefCheck() {
                                                    return null
                                                }}
                                                size={
                                                    windowWidth > 499
                                                        ? 'large'
                                                        : 'medium'
                                                }
                                                subjectTagOnClick={function noRefCheck() {
                                                    return null
                                                }}
                                                subjectTagType='filled'
                                                tagColor='jotaOrange'
                                                tagLabel={post.hat}
                                                tagDisableHover
                                                tagOnClick={function noRefCheck() {
                                                    return null
                                                }}
                                                tagType='colored'
                                                text={post.excerpt}
                                                title={post.title}
                                                titleSearchTerm={''}
                                                titleDisableHover={true}
                                                subjectTagLabel={
                                                    post.inherits_from_PRO
                                                        ? CONTEUDO_LIBERADO
                                                        : ''
                                                }
                                                subjectTagDisableHover
                                                isSponsored={
                                                    post.inherits_from_PRO ??
                                                    false
                                                }
                                            />
                                        </CardContainer>{' '}
                                        <AuthorContainer>
                                            {post.authors.map(
                                                (author, index) => {
                                                    if (
                                                        index ===
                                                        post.authors.length - 1
                                                    ) {
                                                        return author.name
                                                    } else {
                                                        return (
                                                            author.name + ', '
                                                        )
                                                    }
                                                }
                                            )}
                                        </AuthorContainer>
                                        <TimestampContainer
                                            hasSponsor={
                                                !!(
                                                    post.sponsored_post?.logo ||
                                                    post
                                                        .sponsored_category_logos
                                                        .length > 0
                                                )
                                            }
                                        >
                                            <TimeStampColumn>
                                                <DateContainer>
                                                    <span>
                                                        {formatNumbers(
                                                            date?.getDate()
                                                        )}
                                                        /
                                                        {date &&
                                                            formatNumbers(
                                                                date?.getMonth() +
                                                                    1
                                                            )}
                                                        /{date?.getFullYear()}
                                                    </span>
                                                    <span className='divisor'>
                                                        |
                                                    </span>
                                                    <span>
                                                        {formatNumbers(
                                                            date?.getHours()
                                                        )}
                                                        :
                                                        {formatNumbers(
                                                            date?.getMinutes()
                                                        )}
                                                    </span>
                                                    <span>
                                                        {post?.city && (
                                                            <span className='divisor'>
                                                                |
                                                            </span>
                                                        )}
                                                        {post.city}
                                                    </span>
                                                </DateContainer>
                                                {updateContainer(
                                                    date,
                                                    dateRecent
                                                ) && (
                                                    <UpdateContainer>
                                                        Atualizado em{' '}
                                                        <span>
                                                            {formatNumbers(
                                                                dateRecent?.getDate()
                                                            )}
                                                            /
                                                            {dateRecent &&
                                                                formatNumbers(
                                                                    dateRecent?.getMonth()
                                                                ) &&
                                                                formatNumbers(
                                                                    dateRecent?.getMonth() +
                                                                        1
                                                                )}
                                                            /
                                                            {formatNumbers(
                                                                dateRecent?.getFullYear()
                                                            )}
                                                        </span>{' '}
                                                        às{' '}
                                                        <span>
                                                            {formatNumbers(
                                                                dateRecent?.getHours()
                                                            )}
                                                            :
                                                            {formatNumbers(
                                                                dateRecent?.getMinutes()
                                                            )}
                                                        </span>
                                                    </UpdateContainer>
                                                )}
                                            </TimeStampColumn>
                                            {/* <ShareComponent /> */}
                                        </TimestampContainer>
                                        {showSponsoredLogos(post)}
                                        {post?.youtube_link && (
                                            <div>
                                                <iframe
                                                    width='100%'
                                                    height='375'
                                                    src={`https://www.youtube.com/embed/${post.youtube_link}`}
                                                    title='YouTube video player'
                                                    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                                                    allowFullScreen
                                                ></iframe>
                                            </div>
                                        )}
                                        {post?.image.src &&
                                            !post?.youtube_link && (
                                                <ImageNews>
                                                    <img
                                                        src={post?.image.src}
                                                        alt={
                                                            post?.image
                                                                .alternative
                                                        }
                                                        width={'100%'}
                                                    />
                                                    <LegendImage>
                                                        {post?.image.legend}
                                                    </LegendImage>
                                                </ImageNews>
                                            )}
                                        <HTMLContent
                                            dangerouslySetInnerHTML={{
                                                __html: JotaInfoEmbeddings.init(
                                                    post?.content
                                                )
                                                    .check()
                                                    .getHtml()
                                            }}
                                            ref={(node) => {
                                                if (node) {
                                                    getBodyLastParagraph(node)
                                                }
                                            }}
                                        />
                                        <DiplayAuthorsContainer>
                                            {post.authors.map((author) => {
                                                return (
                                                    <AuthorsContainer
                                                        key={generateUUID()}
                                                    >
                                                        <AuthorBioContainer>
                                                            <ProfileCard
                                                                description={
                                                                    author.bio
                                                                }
                                                                firstTopLabel=''
                                                                hasAvatar
                                                                pathAvatarImage={
                                                                    author.photo
                                                                }
                                                                nameSurname={
                                                                    author.name
                                                                }
                                                                onClickFollowTag={() => {
                                                                    return null
                                                                }}
                                                                secondTopLabel=''
                                                                type='tertiary'
                                                            />
                                                        </AuthorBioContainer>
                                                    </AuthorsContainer>
                                                )
                                            })}
                                        </DiplayAuthorsContainer>
                                        {post.tags?.length >= 1 && (
                                            <TagContainer>
                                                <span> Tags </span>
                                                {post.tags?.map((tag) => {
                                                    return (
                                                        <TagDS
                                                            key={generateUUID()}
                                                            onClick={() => {
                                                                router.push(
                                                                    `${URLS.tag}/${tag.slug}`
                                                                )
                                                            }}
                                                            type='outline'
                                                            label={tag.name}
                                                            color='jotaBlack'
                                                            size={'large'}
                                                        />
                                                    )
                                                })}
                                            </TagContainer>
                                        )}
                                    </PostContainer>
                                )}
                            </>
                        ) : (
                            <PostContainer>
                                <LoadingPost />
                            </PostContainer>
                        )}
                    </BodyCenter>

                    {show && footer ? (
                        <FooterComponent footer={footer} />
                    ) : (
                        <LoadingPost />
                    )}
                </Body>
            )}
        </div>
    )
}

export default PostPage

export const getStaticPaths: GetStaticPaths<{ slug: string }> = async () => {
    return {
        paths: [],
        fallback: 'blocking'
    }
}

/**
 * getStaticProps
 * @param {unknown} context
 * @return {Promise<GetStaticPropsResult<HomePagePropsInterface>>}
 */
export const getStaticProps = async (
    context: PropsI
): Promise<GetStaticPropsResult<unknown>> => {
    let output = null
    const slugWithoutDate = /-\d{8}$/
    const cleanSlug = context.params.slug.replace(slugWithoutDate, '')
    const isCategory = context?.query?.isCategory === 'true'

    if (!isCategory && process.env.NEXT_PUBLIC_WP_API_POST) {
        const post = await (
            await fetch(
                `${process.env?.NEXT_PUBLIC_WP_API_POST}?slug=${cleanSlug}`
            )
        ).json()
        const { header, footer } = post['page']
        output = { post, header, footer }
        return {
            props: output,
            revalidate: 10
        }
    } else {
        return {
            props: {
                isCategory: true,
                categoryPath: context.params.slug
            },
            revalidate: 10
        }
    }
}
