import { BreadcrumbContainer } from '@App/libs/style/post-page'
import { generateUUID } from '@App/libs/utils/geral'
import { Breadcrumb } from '@JOTAJornalismo/jota-design-system'
import { useRouter } from 'next/router'
import { FC, useEffect, useState } from 'react'

type BreadI = {
    post: any
}
const categories = [
    'ecommerce-internacional',
    'ambiente-negocios-oportunidades',
    'proteinas-alternativas-economia-verde',
    'seguranca-juridica-desenvolvimento',
    'seguranca-juridica-investimento',
    'transicao-energetica'
]

const BreadCrumComponent: FC<BreadI> = ({ post }) => {
    const [breadItems, setBreadItems] = useState<any[]>([])
    const router = useRouter()

    useEffect(() => {
        const breadcrumbs = post.breadcrumbs[0]

        if (breadcrumbs) {
            if (breadcrumbs.children?.slug.includes('saude')) {
                setBreadItems([
                    { label: 'Início' },
                    {
                        label: 'Saúde',
                        slug: 'saude',
                        clickable: true
                    }
                ])
            } else if (breadcrumbs.children?.slug.includes('energia')) {
                setBreadItems([
                    { label: 'Início' },
                    {
                        label: 'Energia',
                        slug: 'energia',
                        clickable: true
                    }
                ])
            } else if (breadcrumbs.children?.slug.includes('eleicoes')) {
                setBreadItems([
                    { label: 'Início' },
                    {
                        label: breadcrumbs.children?.title,
                        slug: breadcrumbs.children?.slug,
                        clickable: true
                    }
                ])
            } else if (breadcrumbs.slug?.includes('tributos')) {
                setBreadItems([
                    { label: 'Início' },
                    {
                        label: 'Tributos',
                        slug: 'tributos',
                        clickable: true
                    }
                ])
            } else if (
                !breadcrumbs.slug &&
                !breadcrumbs.children?.slug.includes('coberturas-especiais')
            ) {
                if (breadcrumbs.children?.slug.includes('executivo')) {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: 'Poder',
                            slug: 'poder',
                            clickable: true
                        },
                        {
                            label: 'Executivo',
                            slug: 'poder/executivo',
                            clickable: true
                        }
                    ])
                } else if (breadcrumbs.children?.slug.includes('legislativo')) {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: 'Poder',
                            slug: 'poder',
                            clickable: true
                        },
                        {
                            label: 'Legislativo',
                            slug: 'poder/legislativo',
                            clickable: true
                        }
                    ])
                } else if (breadcrumbs.children?.slug.includes('justica')) {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: 'Poder',
                            slug: 'poder',
                            clickable: true
                        },
                        {
                            label: 'Justiça',
                            slug: 'poder/justica',
                            clickable: true
                        }
                    ])
                } else if (breadcrumbs.children?.slug.includes('do-supremo')) {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: 'Poder',
                            slug: 'poder',
                            clickable: true
                        },
                        {
                            label: 'STF',
                            slug: 'poder/stf',
                            clickable: true
                        }
                    ])
                } else if (breadcrumbs.children?.slug.includes('blog')) {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: breadcrumbs.children?.title,
                            slug: breadcrumbs.children?.slug,
                            clickable: false
                        }
                    ])
                } else {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: 'Poder',
                            slug: 'poder',
                            clickable: true
                        }
                    ])
                }
            } else if (breadcrumbs.slug?.includes('stf')) {
                setBreadItems([
                    { label: 'Início' },
                    {
                        label: 'Poder',
                        slug: 'poder',
                        clickable: true
                    },
                    {
                        label: 'STF',
                        slug: 'poder/stf',
                        clickable: true
                    }
                ])
            } else if (
                breadcrumbs?.children?.slug === 'eleicoes' ||
                breadcrumbs?.children?.slug === 'saude'
            ) {
                setBreadItems([
                    { label: 'Início' },
                    {
                        label: breadcrumbs.children?.title.replace('&', 'e'),
                        slug: breadcrumbs.slug,
                        clickable: true
                    }
                ])
            } else if (
                breadcrumbs.children?.slug.includes('coberturas-especiais')
            ) {
                if (post.breadcrumbs.length === 1) {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: breadcrumbs.children?.title,
                            slug: 'coberturas-especiais'
                        }
                    ])
                } else {
                    setBreadItems([
                        { label: 'Início' },
                        {
                            label: post.breadcrumbs[1].title,
                            slug: post.breadcrumbs[1].slug
                        },
                        {
                            label: post.breadcrumbs[1].children?.title,
                            slug: post.breadcrumbs[1].children?.slug
                        }
                    ])
                }
            } else if (
                breadcrumbs.slug?.includes('colunas') ||
                breadcrumbs.slug?.includes('colunas-acervo')
            ) {
                setBreadItems([
                    { label: 'Início' },
                    {
                        label: breadcrumbs.title,
                        slug: `opiniao-e-analise/${breadcrumbs.slug}`
                    },
                    {
                        label: breadcrumbs.children?.title,
                        slug: `opiniao-e-analise/${breadcrumbs.slug}/${breadcrumbs.children?.slug}`,
                        clickable: true
                    }
                ])
            } else if (breadcrumbs?.id) {
                let isClickable = true

                if (breadcrumbs.slug?.includes('coberturas-especiais')) {
                    isClickable = categories.includes(
                        breadcrumbs.children?.slug
                    )
                }

                setBreadItems([
                    { label: 'Início' },
                    {
                        label: breadcrumbs.title.replace('&', 'e'),
                        slug: breadcrumbs.slug
                    },
                    {
                        label: breadcrumbs.children?.title.replace('&', 'e'),
                        slug: breadcrumbs.children?.slug,
                        clickable: isClickable
                    }
                ])
            } else {
                setBreadItems([{ label: 'Início', clickable: false }])
            }
        }
    }, [post.breadcrumbs])

    return (
        <BreadcrumbContainer>
            <Breadcrumb
                key={generateUUID()}
                items={breadItems}
                onClick={(item: any) => {
                    const data = breadItems.filter(
                        (breadcumb) => breadcumb.label == item
                    )[0]

                    if (item == 'Início') {
                        router.push('/')
                    } else if (item === 'Análise') {
                        window.open(`/opiniao-e-analise/analise`, '_self')
                    } else if (item === 'Colunas') {
                        window.open(`/opiniao-e-analise/colunas`, '_self')
                    } else if (item === 'Artigos') {
                        window.open(`/opiniao-e-analise/artigos`, '_self')
                    } else if (categories.includes(data.slug)) {
                        const getVertical = breadItems.filter((breadcumb) =>
                            ['coberturas-especiais'].includes(breadcumb.slug)
                        )[0]

                        window.open(
                            `/${getVertical.slug}/${data.slug}`,
                            '_self'
                        )
                    } else {
                        window.open(`/${data.slug}`, '_self')
                    }
                }}
            />
        </BreadcrumbContainer>
    )
}

export default BreadCrumComponent
